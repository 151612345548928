import { CommonModule }                                               from '@angular/common';
import { HttpClient }                                                 from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type }                        from '@angular/core';
import {
	ComponentTranslationLoaderService, CsCommonPipesModule,
	CsCultureModule, CsCultureProvider
}                                                                     from '@cs/common';
import { CsPlaceholderModule }                                        from '@cs/components/placeholder';
import { CsHyperlinkModule, CsSharedModule }                          from '@cs/components/shared';
import { LoggerUtil }                                                 from '@cs/core';
import { CsApplicationSettings, CsTranslationLoader, IConfigService } from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService }         from '@ngx-translate/core';
import { DownloadsConfigService }                                     from './downloads-config.service';
import { DownloadsRoutingModule }                                     from './downloads-routing.module';
import { DownloadsComponent }                                         from './downloads.component';
import {
	DownloadItemsResolver
}                                                                     from './resolver/download-items.resolver';


export function downloadsTranslationLoader(client: HttpClient,
										   pmAppSettings: CsApplicationSettings,
										   service: IConfigService,
										   componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [
				  DownloadsComponent
			  ],
			  imports:      [
				  CommonModule,
				  CsCommonPipesModule,
				  CsCultureModule,
				  CsHyperlinkModule,
				  TranslateModule.forChild({
											   loader:  {
												   provide:    TranslateLoader,
												   useFactory: downloadsTranslationLoader,
												   deps:       [HttpClient, CsApplicationSettings, DownloadsConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  DownloadsRoutingModule,
				  CsPlaceholderModule,
				  CsSharedModule
			  ],
			  providers:    [DownloadItemsResolver]
		  })
export class PmDownloadsModule {
	static forRoot(moduleConfig: Type<DownloadsConfigService>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<PmDownloadsModule> {
		return {
			ngModule:  PmDownloadsModule,
			providers: [
				{
					provide:  DownloadsConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}

