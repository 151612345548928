import { DataEntryStateColumnType }                             from './data-entry-state';
import { SizeUnit }                                             from '@cs/core';

export class DataEntryVisualState {
	headerType?: 'horizontal' | 'vertical'  = 'horizontal';
	alignment?: 'center' | 'left' | 'right' = 'left';
	placeholder?: boolean;
	visible?: boolean;
	cellTemplate?: DataEntryStateColumnType;
	isFirst?: boolean;
	/**
	 * Width provided by the server
	 */
	width?: SizeUnit<number, string>;
	sticky?: boolean;
}
