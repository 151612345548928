import { Component }                           from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DashboardPanelComponentBase }         from '@cs/components/shared';
import { AuditTrailAudits }                    from '../../components/audit-trail-audits/audit-trail-audits.model';
import { ArrayUtils }                          from '@cs/core';


/**
 * Wrapper for audits component. Component will load it own data through service.
 */

@Component({
			   selector:    'cs-dashboard-audits',
			   templateUrl: './dashboard-audits.component.html',
			   animations:  [
				   trigger('statisticsState', [

					   transition(':leave', [ // each time the binding value changes

						   style({opacity: 1}),
						   animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
								   style({
											 opacity: 0
										 }))

					   ]), transition(':enter', [ // each time the binding value changes

						   style({opacity: 0}),
						   animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
								   style({
											 opacity: 1
										 }))

					   ])
				   ])
			   ]

		   })
export class DashboardAuditsComponent extends DashboardPanelComponentBase<AuditTrailAudits> {


	static TYPE_NAME = 'AuditTrail';
	name: string;

	set data(value: AuditTrailAudits) {
		this._data = value;
	}

	get data() {
		return this._data;
	}

	constructor() {
		super();
	}


	update(data: AuditTrailAudits): void {
		if (this.data && data && ArrayUtils.isEqual(this.data.items, data.items))
			return;

		this.data = data;
	}

	dataChanged(value: AuditTrailAudits) {

	}
}
