import { Validators }            from '@angular/forms';
import { CsValidatorsUtils }     from './cs-validators.utils';
import { IValidationAnnotation } from '../../data-described/validators/validation-annotations';


export enum ValidationAnnotationType {
	Regex       = 'pattern',
	Range       = 'range',
	Required    = 'required',
	Maxlength   = 'maxlength',
	Minlength   = 'minlength',
	InputMask   = 'inputmask',
	AwbNumber   = 'awbnumber',
	ServerError = 'servererror'
}

export class AwbNumberValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {

	type = ValidationAnnotationType.AwbNumber;

	warningMessage?: string;
	errorMessage?: string;

	rem: number;
	serial: number;
	check: number;

	constructor(init: Partial<AwbNumberValidationAnnotation>) {
		this.warningMessage = init.warningMessage;
		this.errorMessage   = init.errorMessage;
	}

	validatorFunc() {
		return [CsValidatorsUtils.awbNumber()];
	}
}


export class RangeValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.Range;
	minimum: number;
	maximum: number;
	errorMessage: string;

	constructor(init: Partial<RangeValidationAnnotation>) {
		this.minimum      = init.minimum;
		this.maximum      = init.maximum;
		this.errorMessage = init.errorMessage;
	}

	validatorFunc() {
		return [CsValidatorsUtils.range(this.minimum, this.maximum)];
	}
}

export class DateRangeValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.Range;
	minimum: number;
	maximum: number;
	errorMessage: string;

	constructor(init: Partial<RangeValidationAnnotation>) {
		this.maximum      = new Date(init.maximum).getTime() / 1000.0;
		this.minimum      = new Date(init.minimum).getTime() / 1000.0;
		this.errorMessage = init.errorMessage;
	}

	validatorFunc() {
		return [CsValidatorsUtils.dateRange(this.minimum, this.maximum)];
	}
}

export class RegexValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.Regex;
	pattern: string;
	errorMessage: string;

	constructor(init: Partial<RegexValidationAnnotation>) {
		this.pattern      = init.pattern;
		this.errorMessage = init.errorMessage;
	}

	validatorFunc() {
		return [Validators.pattern(this.pattern)];
	}
}

export class RequiredValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.Required;
	errorMessage: string;

	constructor(init: Partial<RequiredValidationAnnotation>) {
		this.errorMessage = init.errorMessage;
	}

	validatorFunc() {
		return [Validators.required];
	}
}

export class InputMaskValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.InputMask;
	errorMessage: string;
	warningMessage: string;

	constructor(init: Partial<InputMaskValidationAnnotation>) {
		this.errorMessage   = init.errorMessage;
		this.warningMessage = init.warningMessage;
	}

	validatorFunc() {
		return [];
	}
}

export class DefaultServerValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.ServerError;

	constructor() {
	}

	validatorFunc() {
		return [CsValidatorsUtils.serverError()];
	}
}

export class MaxlengthValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.Maxlength;
	value: number;
	errorMessage: string;

	constructor(init: Partial<MaxlengthValidationAnnotation>) {
		this.value        = init.value;
		this.errorMessage = init.errorMessage;
	}

	validatorFunc() {
		return [Validators.maxLength(this.value)];
	}
}

export class MinlengthValidationAnnotation implements IValidationAnnotation<ValidationAnnotationType> {
	type = ValidationAnnotationType.Minlength;
	value: number;
	errorMessage: string;

	constructor(init: Partial<MinlengthValidationAnnotation>) {
		this.value        = init.value;
		this.errorMessage = init.errorMessage;
	}

	validatorFunc() {
		return [Validators.minLength(this.value)];
	}
}
