import { Injector }                                   from '@angular/core';
import {
	MatDialog
}                                                     from '@angular/material/dialog';
import {
	ColgroupSetting,
	ColgroupSettings, CompareRowState,
	CsDataGrid, DataEntrySaveMethod,
	DataGridCellType, DataGridHelpers, DataGridParser,
	DataGridRuleEnforcer, GridDataRow,
	GridOptions,
	GridSheet,
	GridType, IInitData, RowState
}                                                     from '@cs/components/data-grid';
import { find, Logger }                               from '@cs/components/util';
import { isNullOrUndefined, LoggerUtil, pathChecked } from '@cs/core';
import {
	DialogBasicComponent,
	DialogType,
	getUIPlaceholder,
	isUIPlaceholder,
	ROWBUTTONS_FUNCTIONS,
	ToastService
}                                                     from '@cs/performance-manager/shared';
import {
	TranslateService
}                                                     from '@ngx-translate/core';
import { Observable }                                 from 'rxjs';


export class DataGridUtils {

	/**
	 * Returns parsed InitData structure as GridOptions supplemented with defaults.
	 */
	static getOptions(data: IInitData): GridOptions {
		const colGroup = new ColgroupSettings();

		return {
			gridType:                   GridType.Fill,
			rules:                      DataGridParser.parseRules(data.config.rules),
			injectColumns:              DataGridParser.parseInjectColumns(data.config.client.injectColumns),
			dataKeyParts:               data.facts.dataKeyParts,
			baseKeys:                   data.structureData.baseKeys,
			columnPropertyKey:          data.config.client.columnPropertyKey || 'labelMin',
			aggregateRowsByKeys:        [],
			config:                     data.config.structure,
			choiceSets:                 data.config.structure.dimensionChoiceSets,
			structureData:              data.structureData,
			memberLists:                data.structureData.memberLists,
			isNested:                   false,
			fixedSize:                  colGroup.fixedSize,
			client:                     data.config.client,
			colgroupSettings:           colGroup,
			useDataSourceValueAsKey:    data.config.structure.useDataSourceValueAsKey,
			allowSaveDatasources:       [1],
			showCheckboxes:             data.config.layout.showCheckboxes || false,
			useSaveBar:                 isNullOrUndefined(data.config.layout.useSaveBar)
										? true
										: data.config.layout.useSaveBar,
			saveMethod:                 isNullOrUndefined(data.config.layout.saveMethod)
										? DataEntrySaveMethod.BottomBar
										: data.config.layout.saveMethod,
			disableSorting:             data.config.layout.disableSorting || false,
			allowSaveDuplicateCellKeys: isNullOrUndefined(data.config.layout.allowSaveDuplicateCellKeys)
										? true
										: data.config.layout.allowSaveDuplicateCellKeys,  // Default true for backwards compatibility
			rowButtons:                 DataGridParser.parseRowButtons(data.config.layout.rowButtons, ROWBUTTONS_FUNCTIONS),
			enableContinuesScrolling:   pathChecked(data, ['config', 'layout', 'enableContinuesScrolling'], false)
		};
	}

	static setupButtons(data: IInitData, injector: Injector) {
		const i18nApp = injector.get(TranslateService, null);
		let buttons   = [];

		if (!isNullOrUndefined(data.config.export)) {
			data.config.export.forEach(b => b.type = 'Export');
			buttons = buttons.concat(data.config.export);
		}
		if (!isNullOrUndefined(data.config.import)) {
			data.config.import.forEach(b => b.type = 'Import');
			buttons = buttons.concat(data.config.import);
		}

		if (!isNullOrUndefined(data.config.buttons)) {
			data.config.buttons.forEach(b => b.type = b.name);
			buttons = buttons.concat(data.config.buttons);
		}

		// search for translatable strings
		buttons.forEach(value => {
			value.label = isUIPlaceholder(value.label)
						  ? getUIPlaceholder(value.label, i18nApp)
						  : value.label;

			value.description = isUIPlaceholder(value.description)
								? getUIPlaceholder(value.description, i18nApp)
								: value.description;
		});
		return {dynamicButtons: buttons};
	}

	static convertToSelectionKey(newApiParams: {
		[p: string]: any
	}) {
		const out = {};

		for (const key of Object.keys(newApiParams)) {
			out[key] = {
				id: newApiParams[key]
			};
		}
		return out;
	}

	/**
	 * Automatically calculates the width of the column headers, based on the amount of total columns.
	 * Take the first 300px to be equal of the charts panel into account
	 */
	static calculateDataGridCellWidth(gridRef: CsDataGrid, options: GridOptions, sheet: GridSheet, sheets: GridSheet[],
									  showChart: boolean, defaultWidthForLabels = 300) {

		// Added a div called width-ruler, use this size to calculate the cells
		const ruler = document.getElementById('width-ruler');
		if (isNullOrUndefined(ruler)) {
			LoggerUtil.warn('Missing html element with id="width-ruler"');
		}
		// const size = isNullOrUndefined(this.chartPanel) ? {width: 1306} : this.chartPanel.getClientRect();
		const size = ruler.getBoundingClientRect();

		const colGroup = new ColgroupSettings();

		colGroup.labelsTotalWidth = defaultWidthForLabels;
		colGroup.sizes            = [];

		if (sheets.length === 0 || sheets[0].groups.length === 0)
			return;

		const group  = sheets[0].groups[0];
		const colRow = group.columsRows[group.columsRows.length - 1];

		let labelSize        = size.width < 1024
							   ? 125
							   : colGroup.labelsTotalWidth;
		let orginalLabelSize = labelSize;
		if (options.showCheckboxes) {
			labelSize -= 35;
			colGroup.sizes.push(new ColgroupSetting(DataGridCellType.Checkbox, 35, `px`));
		}
		if (options.rowButtons && options.rowButtons.length > 0) {
			colGroup.sizes.push(new ColgroupSetting(DataGridCellType.RowMenu, 25 * options.rowButtons.length, `px`));
		} else {
			colGroup.sizes.push(new ColgroupSetting(DataGridCellType.RowMenu, 0, `px`));
		}

		const injectedColumns = colRow.columns.filter(x => x.cellType === DataGridCellType.Injected);

		if (injectedColumns.length > 0) {
			let totalGivenInjectedSizes = 0;
			injectedColumns.forEach((colA) => {
				const colAW = !isNullOrUndefined(colA.width)
							  ? colA.width.replace('px', '')
							  : '0';
				totalGivenInjectedSizes += parseFloat(colAW);
			});

			const hasLength = injectedColumns.filter(col => isNullOrUndefined(col.width));

			labelSize -= totalGivenInjectedSizes;

			if (totalGivenInjectedSizes > orginalLabelSize)
				orginalLabelSize = totalGivenInjectedSizes;

			if (hasLength.length > 0)
				labelSize /= hasLength.length;

			colGroup.sizes.push(new ColgroupSetting(DataGridCellType.Injected, labelSize, `px`));
		} else {
			colGroup.sizes.push(new ColgroupSetting(DataGridCellType.Label, labelSize, `px`));
		}

		// Check if there is a chart shown
		colGroup.fixedSize       = true;
		sheet.settings.fixedSize = (sheet.settings.hasOwnProperty('doNotCalculateSize') && sheet.settings.doNotCalculateSize)
								   ? false
								   : true;

		const nrOfDataCells = colRow.columns.filter(x => x.cellType === DataGridCellType.Data).length;
		const hasOffset     = colRow.columns.find(x => x.cellType === DataGridCellType.Offset);
		const hasTotal      = colRow.columns.find(x => x.cellType === DataGridCellType.Total);
		const hasRowMenu    = colRow.columns.find(x => x.cellType === DataGridCellType.RowMenu);

		let cellWidth = size.width - orginalLabelSize;

		if (hasRowMenu) {
			const offset = colGroup.sizes.find(x => x.cellType === DataGridCellType.RowMenu);
			cellWidth    = cellWidth - offset.widthNr;
		}
		if (hasOffset) {
			const offset = (new ColgroupSettings().sizes.find(x => x.cellType === DataGridCellType.Offset));
			cellWidth    = cellWidth - offset.widthNr;
		}
		if (hasTotal) {
			const total = (new ColgroupSettings().sizes.find(x => x.cellType === DataGridCellType.Total));
			// If there is an offset column or there is a Chart just use the maximum width
			// otherwise we need to align the chart legend with the data columns

			if (!hasOffset) {
				const override = new ColgroupSetting(DataGridCellType.Total, Math.floor(cellWidth / (nrOfDataCells + 1)), 'px');
				total.width    = override.width;
				total.widthNr  = override.widthNr;
				colGroup.sizes.push(override);
			}

			cellWidth = cellWidth - total.widthNr;
		}

		cellWidth = Math.floor(cellWidth / nrOfDataCells);

		colGroup.sizes.push(new ColgroupSetting(DataGridCellType.Data, cellWidth, `px`));

		this.parseColGroupSettings(colGroup, sheet);
		if (!isNullOrUndefined(gridRef) && !gridRef.changeRef['destroyed']) {
			gridRef.changeRef.detectChanges();
		}
	}

	static parseColGroupSettings(colgroupSettings: ColgroupSettings, sheet: GridSheet) {
		sheet.colGroup = [];
		for (const group of sheet.groups) {
			const headerRow = group.columsRows[group.columsRows.length - 1];
			for (let headerIndex = 0; headerIndex < headerRow.columns.length; headerIndex++) {
				const headerCol = headerRow.columns[headerIndex];
				if (!isNullOrUndefined(headerCol.width)) {
					sheet.colGroup.push({key: headerCol.key, width: headerCol.width});
				} else {
					const foundSize = colgroupSettings.sizes.find(x => x.cellType === headerCol.cellType
						|| (x.cellType === DataGridCellType.Label && headerCol.isLabel));
					if (foundSize) {
						sheet.colGroup.push({key: headerCol.key, width: foundSize.width});
					} else {
						sheet.colGroup.push({key: headerCol.key, width: ''});
					}
				}
			}
			// only use the first group
			break;
		}
	}

	/**
	 * Formats and shows multiline user messages. Uses Toast for short messages otherwide a Dialog is opened.
	 * @param dialogThreshold maximum number of lines a toast message should contain
	 */
	static displayMultilineUserMessage(injector: Injector,
									   messages: string[],
									   title: string    = 'Error',
									   type: DialogType = DialogType.danger,
									   dialogThreshold  = 3): void {

		const toastService  = injector.get(ToastService);
		const dialogService = injector.get(MatDialog);

		// convert to list
		messages          = messages.reduce((a, b) => a = [...a, `<li>${b}</li>`], []);
		const htmlmessage = `<ul>` + messages.join(`\n`) + `</ul>`;

		// toast types: error,alert,successes,info,bare
		// dialog types: danger,warning,success,info,none
		let toastType = '';
		switch (type) {
			case DialogType.danger:
				toastType = 'error';
				break;
			case DialogType.warning:
				toastType = 'alert';
				break;
			case DialogType.success:
				toastType = 'success';
				break;
			case DialogType.info:
				toastType = 'info';
				break;
			default:
				toastType = 'bare';
				break;
		}


		// show toast for short messages, else use a dialog
		if (messages.length <= dialogThreshold) {
			// notify the user (some magic to preserve newlines)
			const html = `<div class="cs-title">${title}</div>
                      <div class="cs-content multiline-nowrap">${htmlmessage}</div>`;
			toastService.html(toastType, html);
		} else {
			dialogService.open(DialogBasicComponent,
							   {
								   data:         {
									   dialogTitle: title,
									   message:     htmlmessage,
									   showYes:     false,
									   showNo:      false,
									   showCancel:  false,
									   showOk:      true,
									   type:        type
								   },
								   disableClose: true
							   });
		}
	}

	static compareSheets(sheets: Array<GridSheet>, compareSheets: Array<GridSheet>, options: GridOptions, compareOptions: GridOptions) {
		const obs = new Observable<Array<GridSheet>>(subscriber => {
			for (const sheet of sheets) {
				// Find the sheet to compare based on sheet key and value
				const compareSheet = compareSheets.find(x => {
					return x.key === sheet.key && x.keys[sheet.key] === sheet.keys[sheet.key];
				});

				if (!isNullOrUndefined(compareSheet)) {
					DataGridUtils.compareSheet(sheet, compareSheet, options, compareOptions);
					DataGridRuleEnforcer.executeDynamicRules(sheet);
				}
			}
			subscriber.next(sheets);
			subscriber.complete();
		});

		return obs;
	}

	static compareSheet(sheet: GridSheet, compareSheet: GridSheet, options: GridOptions, compareOptions: GridOptions): void {
		// flatten the rows for simplicity. Now you are able to walk down the list without group rows
		// Also it makes removing found rows easier
		const flattenRows = DataGridHelpers.flattenRows(compareSheet);

		for (const group of sheet.groups) {

			if (isNullOrUndefined(group.dataRows)) {
				Logger.Warning(`No dataRows on the group: ${group.keys}`);
			}
			for (const dataRow of group.dataRows) {

				if (dataRow.isGroup) {
					continue;
				}

				const dataRowKeys = DataGridHelpers.cleanBaseKeys(options, dataRow.keys);
				let compareRow;
				if (dataRow.rowState === RowState.Total) {
					// Find the Total row
					compareRow = flattenRows.find((x: GridDataRow) => x.rowState === RowState.Total);
				} else if (!isNullOrUndefined(dataRowKeys) && dataRowKeys !== {}) {
					// Find in the flatten rows for an row that has the dataRowKeys, and remove from array when found
					compareRow = find<GridDataRow>(flattenRows, dataRowKeys, 'keys', true);
				}
				if (!isNullOrUndefined(compareRow)) {
					for (let i = 0; i < dataRow.values.length; i++) {
						const cell        = dataRow.values[i];
						const compareCell = compareRow.values[i];
						cell.compareCell  = compareCell;
						cell.resolveMetaValues();
						dataRow.compareRowState = CompareRowState.Found;
					}
				} else {
					dataRow.compareRowState = CompareRowState.NotFound;
				}
			}
		}

	}
}
