import { convertKeysToFnv32a, DataViewColumnPosition, Dictionary, generateQuickGuid, getPropertyOf, OptionalExceptFor } from '@cs/core';


export abstract class DataViewColumn<TValue, TColumnType, TVisualState = unknown, TValueType = string> {
	/**
	 * What kind of column am i?
	 */
	abstract type: TColumnType;
	/**
	 * The level key of the group. Group2 for example
	 */
	levelKey: string | number;
	/**
	 * The level key value of the group. Like OPS_State
	 */
	levelValue: string | number;
	/**
	 * The raw value for this cell
	 */
	value: TValue;
	/**
	 * Object that holds the absolute position for this cell
	 */
	position: DataViewColumnPosition;
	/**
	 * The type of data, is used for edit templates
	 */
	valueType: TValueType;
	/**
	 * Placeholder for all state related properties that a component can implement
	 */
	visualState: TVisualState | null;
	/**
	 * The keys for this columns.
	 */
	readonly key: Dictionary;

	readonly path: string[];
	/**
	 * Unique identifier for column
	 */
	readonly id: string;

	/**
	 * The combination of the @Link{levelKey} and @Link{levelValue} as a stucture identifiers
	 */
	get structureKey(): string {
		if (this._structureKey == null)
			this._structureKey = convertKeysToFnv32a({[this.levelKey]: this.levelValue});
		return this._structureKey;
	}


	protected constructor(init: DataViewColumnInit<TValue, TColumnType, TVisualState, TValueType>) {
		this.value       = init.value;
		this.levelKey    = init.levelKey;
		this.levelValue  = init.levelValue;
		this.valueType   = init.valueType;
		this.path        = init.path;
		this.position    = init.position;
		this.id          = generateQuickGuid();
		this.visualState = getPropertyOf(init, 'visualState', null);
	}

	private _structureKey: any;
}

export type DataViewColumnInit<TValue, TColumnType, TVisualState = unknown, TValueType = string> =
	OptionalExceptFor<DataViewColumn<TValue, TColumnType, TVisualState, TValueType>, 'value' | 'levelValue' | 'levelKey' | 'valueType'>;
