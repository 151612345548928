import { CommonModule }                                                              from '@angular/common';
import { HttpClient }                                                                from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type }                                       from '@angular/core';
import { FormsModule }                                                               from '@angular/forms';
import { MatRippleModule }                                                           from '@angular/material/core';
import { MatDialogModule }                                                           from '@angular/material/dialog';
import { MatTooltipModule }                                                          from '@angular/material/tooltip';
import { RouterModule }                                                              from '@angular/router';
import { ComponentTranslationLoaderService, CsCommonPipesModule, CsCultureProvider } from '@cs/common';
import { CsDataPickerCultureModule, CsDatepickerModule }                             from '@cs/components/datepicker';
import { CsFormGeneratorModule }                                                     from '@cs/components/form-generator';
import { ImageSliderModule }                                                         from '@cs/components/image-slider';
import { NgbCollapseModule }                                                         from '@cs/components/ngb-collapse';
import { CsSharedModule }                                                            from '@cs/components/shared';
import { LoggerUtil }                                                                from '@cs/core';
import { CsApplicationSettings, CsTranslationLoader, IConfigService, ToastService }  from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService }                        from '@ngx-translate/core';
import { ReportingConfigService }                                                    from './reporting-config.service';
import {
	ReportingDashboardModule
}                                                                                    from './reporting-dashboard/reporting-dashboard.module';
import { ReportingRoutingModule }                                                    from './reporting-routing.module';
import { ReportingComponent }                                                        from './reporting.component';
import { ReportingQuery, ReportingStateService, ReportingStore }                     from './state';

export function reportingTranslationLoader(client: HttpClient,
										   pmAppSettings: CsApplicationSettings,
										   service: IConfigService,
										   componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [ReportingComponent],
			  providers   : [ToastService,
							 ReportingStore,
							 ReportingQuery,
							 ReportingStateService],
			  imports     : [
				  CommonModule,
				  FormsModule,
				  RouterModule,
				  CsDataPickerCultureModule,
				  CsDatepickerModule,
				  MatRippleModule,
				  MatDialogModule,
				  ImageSliderModule,
				  CsSharedModule,
				  CsCommonPipesModule,
				  ReportingRoutingModule,
				  CsFormGeneratorModule,
				  ReportingDashboardModule,
				  TranslateModule.forChild({
											   loader : {
												   provide   : TranslateLoader,
												   useFactory: reportingTranslationLoader,
												   deps      : [HttpClient, CsApplicationSettings, ReportingConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  MatTooltipModule,
				  NgbCollapseModule
			  ]
		  })
export class PmReportingModule {
	static forRoot(moduleConfig: Type<ReportingConfigService>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<PmReportingModule> {
		return {
			ngModule : PmReportingModule,
			providers: [
				{
					provide : ReportingConfigService,
					useClass: moduleConfig
				},
				{
					provide : CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not setup');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
