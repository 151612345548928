import {
	Component, OnInit, ChangeDetectorRef, Inject,
	OnDestroy, AfterViewInit, ViewChild
}                                                      from '@angular/core';
import { CsPlaceholderComponent }                      from '@cs/components/placeholder';
import { ChangeLogConfigService }                      from './change-log-config.service';
import { isNullOrUndefined }                           from '@cs/core';
import { from, Subject }                               from 'rxjs';
import { flatMap, groupBy, reduce, tap, debounceTime } from 'rxjs/operators';
import { CategorizedChanges, Changelog, ChangeSet }    from './models/change-log-ui.models';
import { DomSanitizer }                                from '@angular/platform-browser';
import { TabService }                                  from '@cs/performance-manager/tabbed-page';
import { WaitingForResponse }                          from '@cs/common';
import { UntilDestroy, untilDestroyed }                from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
	selector:    'pm-change-log',
	templateUrl: './change-log.component.html'
})
export class ChangeLogComponent implements OnInit, OnDestroy, AfterViewInit {

	changelog: Changelog;

	@ViewChild(CsPlaceholderComponent) placeholder: CsPlaceholderComponent;
	rows$: Subject<number> = new Subject();
	isLoadingDebounced$    = this.rows$.pipe(untilDestroyed(this), debounceTime(300));

	constructor(private appService: ChangeLogConfigService,
							private sanitizer: DomSanitizer,
							public readonly changeRef: ChangeDetectorRef,
							@Inject(TabService) private tabService: TabService) {
	}

	ngOnInit() {
		this.appService.getChangeLog()
				.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))))
				.pipe(tap(() => this.rows$.next(0)))
				.subscribe((result) => {

					const list       = Array.isArray(result.value) ? result.value : [result.value];
					const changelogs = Array<Changelog>();
					this.changelog   = new Changelog();

					for (const item of list) {
						const changelog           = new Changelog();
						changelog.issueTrackerUrl = <string>item.issueTrackerUrl;

						if (isNullOrUndefined(item.issueNumberRegex))
							changelog.issueNumberRegex = null;
						else
							// Also capture optional round brackets, as the issue number is to be displayed as a badge.
							changelog.issueNumberRegex = new RegExp(<string>('\\(?(' + item.issueNumberRegex + ')\\)?'), 'g');

						// When issue regex is not supplied, the issue number cannot be recognized and therefore cannot be linked
						const issueNumberLink: string =
										isNullOrUndefined(item.issueNumberRegex)
											? null
											: isNullOrUndefined(item.issueTrackerUrl)
												// Show issue number as badge, but don't link
												? '<span target="issuetracker" href=\"'
												+ changelog.issueTrackerUrl.replace('{0}', '$$1')
												+ '\" class=\"badge badge-primary mb-0 mr-0 pb-0\">$1</a>'
												// Show issue number as badge with link
												: '<a target="issuetracker" href=\"'
												+ changelog.issueTrackerUrl.replace('{0}', '$$1')
												+ '\" class=\"badge badge-primary mb-0 mr-0 pb-0\">$1</a>';

						// Add all changsets
						item.changeSets.forEach(changeSet => {

							const s = new ChangeSet();
							s.date  = new Date(changeSet.date);
							s.name  = changeSet.name;

							// group changes by category
							const groups = from(changeSet.changes)
								.pipe(
									groupBy((change: any) => change.category),
									flatMap(group => group.pipe(reduce(
										// aggregate by adding change to group
										(acc, curr) => {
											acc.changes.push(curr);
											return acc;
										},
										// seed
										{category: group.key, changes: []}))));


							groups.subscribe(value => {

									const c    = new CategorizedChanges();
									c.category = value.category;

									// // group changes within a category by message (and gather commit hashes)
									const changes =
													from(value.changes)
														.pipe(
															groupBy((change: any) => change.message),
															flatMap(group => group.pipe(reduce(
																// aggregate by adding commit hash to grouped message
																(acc, curr) => {
																	acc.repositoryUrls.url = <string>item.repositoryUrl;
																	if (!isNullOrUndefined(curr.hash) && curr.hash.length > 0)
																		acc.repositoryUrls.hashes.push(curr.hash);
																	return acc;
																},
																{
																	message: (
																						 // Issue number link is null when regex or issue tracker url is not supplied
																						 // In the case the issue is not linked (and not remeoved either)
																						 this.sanitizer.bypassSecurityTrustHtml(issueNumberLink
																							 ? <string>group.key
																								 // linkify issue numbers in message
																															.replace(
																																changelog.issueNumberRegex,
																																issueNumberLink
																															)
																							 : <string>group.key))
																	,
																	repositoryUrls: {url: '', hashes: []}
																}))));


									changes.subscribe(x => c.changes.push(x));
									s.categorizedChanges.push(c);
								}
							);

							s.totalChangeCount = s.categorizedChanges.reduce((x, y) => {
								return x + y.changes.length;
							}, 0);

							changelog.changeSets.push(s);
						});
						changelogs.push(changelog);
					}

					// Get all changeSets on the main ChangeLog
					for (const changelog of changelogs) {
						this.changelog.changeSets.push(...changelog.changeSets);
					}

					this.changelog.changeSets = this.changelog.changeSets.sort((a, b) => b.date.getTime() - a.date.getTime());

					if (list.length > 1) {
						// Merge ChangeSets that match name and date
						this.changelog.changeSets = this.changelog.changeSets.reduce(mergeChangeSets, {
							store: {},
							list:  []
						}).list;

						// Merge Changes from each ChangeSet, that match category
						for (const changeSet of this.changelog.changeSets) {
							changeSet.categorizedChanges = changeSet.categorizedChanges.reduce(mergeCategories, {
								store: {},
								list:  []
							}).list;

							// Change the total amount of changes
							changeSet.totalChangeCount = changeSet.categorizedChanges.reduce((x, y) => {
								return x + y.changes.length;
							}, 0);
						}
					}
				});
	}

	ngAfterViewInit(): void {
		this.rows$.next(1);
		this.placeholder.detectChanges();
	}

	ngOnDestroy(): void {
	}
}


function mergeChangeSets(collector, type) {
	const date = new Date(type.date);
	date.setHours(0, 0, 0, 0);
	const key        = (type.name + '@' + date); // identity key.
	const store      = collector.store;
	const storedType = store[key];
	if (storedType) {
		storedType.categorizedChanges = storedType.categorizedChanges.concat(type.categorizedChanges);
	} else {
		store[key] = type;
		collector.list.push(type);
	}
	return collector;
}

function mergeCategories(collector, type) {
	const key        = (type.category); // identity key.
	const store      = collector.store;
	const storedType = store[key];
	if (storedType) {
		storedType.changes = storedType.changes.concat(type.changes);
	} else {
		store[key] = type;
		collector.list.push(type);
	}
	return collector;
}
