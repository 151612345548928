import { isString } from './determine.utils';

export enum LogType {
  Debug   = 'Debug',
  Warning = 'Warning',
  Error   = 'Error',
  Log     = 'Log'
}

export interface ConsoleMsg {
  /**
   * Shows a text message
   */
  m: string;
  /**
   * Provide the payload that should be dumped in the console,
   * This will appear after the message provided in this object
   */
  p: any;
}

export class LoggerUtil {

  static environment = 'development';

  static write(message: string, logType: LogType = LogType.Log) {

    let output = message;
    if (isString(message))
      output = '%c' + message;

    switch (logType) {
      case LogType.Log:
        console.log(output, 'color: DodgerBlue');
        break;
      case LogType.Debug:
        console.log(output, 'color: #bada55');
        break;
      case LogType.Error:
        console.error(message);
        break;
      case LogType.Warning:
        console.warn(message);
        break;
    }

  }

  static error(message: string | Error, throwErr: boolean = false) {
    if (throwErr && message instanceof Error)
      throw message;
    else if (throwErr && (typeof (message) === 'string'))
      throw new Error(message);
    else if (!throwErr && (typeof (message) === 'string'))
      this.write(message, LogType.Error);
  }

  /**
   * Use the debug method to identify issues will be turned off on production builds
   */
  static warn(message: string) {
    if (this.environment === 'production') return;

    this.write(message, LogType.Warning);
  }

  /**
   * Use the method to identify issues will NOT be turned off on production builds
   */
  static log(message: string) {
    this.write(message, LogType.Log);
  }

  /**
   * Use the method to identify issues will be turned off on production builds
   */
  static debug(message: any) {
    if (this.environment === 'production') return;

    this.write(message, LogType.Debug);
  }
}


