import { DataEntryStateColumnType } from '../models/data-entry-state';
import { DataEntryVisualState }     from '../models/data-entry-state-visual-state';
import { DataStructure }            from '@cs/core';
import { DataEntryStateColumn }     from '../models/data-entry-state-column';

export enum DataEntryHeaderTypes {
	VERTICAL_HEADER = 'vertical-header'
}

export enum DataEntryDataTypes {
	DATA_ENTRY_STATE_ICON       = 'data-entry-state-icon',
	DATA_ENTRY_STATE_ICON_LABEL = 'data-entry-state-icon-label',
	LABEL_EXPAND                = 'label-expand',
	DATA_ENTRY_STATE_INDICATOR  = 'data-entry-state-indicator',
}

export class DataEntryStateStructure extends DataStructure {

	/**
	 * Component specific rules for the width of the cells
	 * @param availableWidth
	 * @protected
	 */
	protected updateHeaderRootCellsWidth(availableWidth: number) {


		const lastNodes       = this.headerStructureLastNodes;
		const labelPercentage = (0.20 * this.groupOrderRows.length);
		const allLabelsWidth  = 250;
		const dataCellSpace   = availableWidth - allLabelsWidth;

		const cellWidth  = dataCellSpace / (lastNodes.length - 1);
		const labelWidth = allLabelsWidth; // injected columns;

		for (let index = 0; index < lastNodes.length; index++) {
			const node = lastNodes[index];

			const header = this.headerRoot.columns.find(value => value.levelValue == node.levelValue) as DataEntryStateColumn;
			const state  = header.visualState as DataEntryVisualState;

			const isSingle = node.parent && Object.keys(node.parent.children).length === 1;

			if (state.width.size) {
				node.width = state.width.size;
				continue;
			}

			switch (state.cellTemplate) {
				case DataEntryStateColumnType.DataEntryState:
					node.width = isSingle
											 ? 75
											 : 25;
					break;
				case DataEntryStateColumnType.Label:
					node.width = labelWidth;
					break;
				case DataEntryStateColumnType.Text:
					node.width = 100;
					break;
				case DataEntryStateColumnType.Status:
					node.width = 25;
					break;
				case DataEntryStateColumnType.StatusWithLabel:
					node.width = 150;
					break;
			}

		}
	}

}
