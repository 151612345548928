import { GridGroup }                                  from './grid-group.model';
import { IProperty }                                  from '@cs/core';
import { createToObjectWithLowerCaseKeys, isString }  from '@cs/components/util';
import { DataGridSheetSettings, GridSheetMetaValues } from '../classes';
import { IMatchLevelKeys }                            from '../interfaces/IMatchLevelKeys';
import { DataGridSheetCalculator }                    from '../utils';

/**
 * Sheet definition for the data grid. This model is used for rendering the data-grid
 */
export class GridSheet implements IMatchLevelKeys {
	groups: Array<GridGroup>            = [];
	calculator: DataGridSheetCalculator = new DataGridSheetCalculator(this);
	colGroup: Array<{ key: string, width: string }>;
	metaValues: GridSheetMetaValues     = new GridSheetMetaValues();

	properties: IProperty;
	settings: DataGridSheetSettings = new DataGridSheetSettings();

	get key(): any {
		return this._key;
	}

	set key(value: any) {
		this._key = isString(value)
					? value.toLowerCase()
					: value;
	}

	get keys(): any {
		return this._keys;
	}

	set keys(value: any) {
		const copy = {};
		for (const key of Object.getOwnPropertyNames(value)) {
			copy[key] = value[key];
		}
		this._keys = createToObjectWithLowerCaseKeys(copy);
	}

	/**
	 * Initialize a new Sheet for the data grid
	 * @param key the key name of the dimension
	 * @param keys key name with the value. Like {idIndicator:1000}
	 * @param properties Properties for the sheet.
	 */
	constructor(key, keys: { [key: string]: any }, properties: IProperty) {
		// Set the key used for sheet identification
		this.key        = key;
		// Set Sheet keys for identification
		this.keys       = keys;
		// Set properties for display purposes
		this.properties = properties;
	}
	private _key: any;
	private _keys: any;


}
