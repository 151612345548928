import { NgModule, ModuleWithProviders, Type } from '@angular/core';
import { CommonModule }                        from '@angular/common';
import { NgbAccordionModule }                  from '@cs/components/accordion';
import { CsPlaceholderModule }                 from '@cs/components/placeholder';
import { CsToastManagerModule }                from '@cs/components/toast-manager';

import { ChangeLogRoutingModule }                             from './change-log-routing.module';
import { ChangeLogComponent }                                 from './change-log.component';
import { ChangeLogConfigService }                             from './change-log-config.service';
import { HttpClient }                                         from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { LoggerUtil }                                           from '@cs/core';
import { ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';

import { CsTranslationLoader }                                          from '@cs/performance-manager/shared';
import { CsApplicationSettings }                                        from '@cs/performance-manager/shared';
import { IConfigService }                                               from '@cs/performance-manager/shared';

export function changeLogTranslationLoader(client: HttpClient,
																					 pmAppSettings: CsApplicationSettings,
																					 service: IConfigService,
																					 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
		client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
	declarations: [ChangeLogComponent],
	imports:      [
		CommonModule,
		CsToastManagerModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: changeLogTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, ChangeLogConfigService, ComponentTranslationLoaderService]
			},
			isolate: true
		}),
		NgbAccordionModule,
		CsPlaceholderModule,
		ChangeLogRoutingModule
	]
})
export class PmChangeLogModule {
	static forRoot(moduleConfig: Type<ChangeLogConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmChangeLogModule> {
		return {
			ngModule:  PmChangeLogModule,
			providers: [
				{
					provide:  ChangeLogConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use(culture.getCulture());
	}
}
