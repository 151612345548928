import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	Injector,
	OnDestroy,
	OnInit, Optional,
	ViewChild
}                                                                             from '@angular/core';
import { DomSanitizer, SafeHtml }                                             from '@angular/platform-browser';
import { FilterSelectionChangedEventArgs }                                    from '@cs/components/advanced-dropdown';
import { DashboardComponent, DashboardGridData }                              from '@cs/components/dashboard';
import { CsDataGrid, CsDataGridPopoversService, DataGridCellType, IInitData } from '@cs/components/data-grid';
import {
	dimensionTreeToFilterNavbar,
	FilterBarDataSource, FilterBarResultParams, FilterBarResultParamsSelection, FilterCompareBarQuery, FilterCompareBarService
}                                                                             from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                                              from '@cs/components/toast-manager';
import { DataEntryConfigService }                                             from './data-entry-config.service';
import {
	UntilDestroy,
	untilDestroyed
}                                                                             from '@ngneat/until-destroy';
import { debounceTime, filter }                                               from 'rxjs/operators';
import { FastZipObject, isNullOrUndefined }                                   from '@cs/core/utils';
import { DataEntryResultParams }                                              from './models/data-entry-result-params';
import { DataGridWorker }                                                     from './agents/data-grid.worker';
import { CsChartPanelComponent }                                              from './components/pm-chart-panel/chart-panel.component';
import { AppService, BottomBarService }                                       from '@cs/performance-manager/shared';
import { ChartPanelAgent }                                                    from './agents/chart-panel.agent';
import { DataEntryStateQuery }                                                from './state/data-entry-state.query';
import { fromEvent, Observable, Subscription }                                from 'rxjs';
import { DataEntryStateService }                                              from './state/data-entry-state.service';
import {
	flattenObject, LoggerUtil,
	restoreFlattenObject,
	generateQuickGuid, TableDataDescribed, SelectionTargetResult, gv
}                                                                             from '@cs/core';
import {
	AppMessageHubService, AppNavigationService,
	opacityAndBlur, SafeMethods
}                                                                             from '@cs/common';
import { DataEntryAgent }                                                     from './agents/data-entry.agent';
import { FilterNavbarLoadedComponent }                                        from '@cs/performance-manager/filter-and-navbar-shell';
import { AuditTrailStateQuery }                                               from '@cs/components/audit-trail';
import { AuditTrailChangesComponent }                                         from '@cs/components/audit-trail';
import { TranslateService }                                                   from '@ngx-translate/core';
import { AuditTrailStateService }                                             from '@cs/components/audit-trail';
import { IDataSource }                                                        from './components/statistics-panel/interfaces/interfaces';
import { ActivatedRoute, Router }                                             from '@angular/router';
import { DataGridUtils }                                                      from './agents/data-grid.utils';
import {
	DashboardBase, DashboardConfigService,
	handlePostAction,
	onDashboardEntryClick
}                                                                             from '@cs/performance-manager/dashboard';
import { TabService }                                                         from '@cs/performance-manager/tabbed-page';
import { animate, state, style, transition, trigger }                         from '@angular/animations';


@UntilDestroy()
@Component({
			   selector:        'pmc-data-entry',
			   templateUrl:     './data-entry.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   animations:      [
				   opacityAndBlur('loadingPanelState'),
				   trigger('isCollapsed', [
					   state('false', style({height: '*', opacity: 1})),
					   state('true', style({height: 0, opacity: 0, padding: 0, pointerEvents: 'none'})),
					   transition('true <=> false', [
						   animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')
					   ])
				   ])
			   ]
		   })
export class DataEntryComponent extends DashboardBase implements FilterNavbarLoadedComponent,
																 OnInit,
																 AfterViewInit,
																 OnDestroy {

	panelContentTopBar: ElementRef<any>;

	/**
	 * Angular instance of the grid on this page
	 */
	@ViewChild('data')
	grid: CsDataGrid;

	/**
	 * The agent that handles the functionality for the chart panel
	 */
	chartPanelAgent: ChartPanelAgent;
	/**
	 * The agent that handles the data entry grid actions
	 */
	dataEntryGrid: DataGridWorker;
	/**
	 * Flag indicating when to show or collapse the chart
	 */
	showChart$: Observable<boolean>;
	/**
	 * Loading flag. Will show a loader in the statistic panel
	 */
	isStatisticsPanelLoading$: Observable<boolean>;
	/**
	 * Loading flag. Will show a loader in the chart panel
	 */
	isChartPanelLoading$: Observable<boolean>;
	/**
	 * Loading flag. Will show a blurred data grid
	 */
	isLoadingDataGrids$: Observable<boolean>;
	/**
	 * Flag if the end of page scroll event should fire
	 */
	enableScrollDetection$: Observable<boolean>;
	/**
	 * Flag indicating when to show the panel containing audit trail changes
	 */
	isChangesPanelVisible$: Observable<boolean>;
	/**
	 * Flag loading new content
	 */
	isLoadingNewContent$: Observable<boolean>;
	/**
	 * Current request handeling a navbar update
	 */
	currentRequest: Subscription;
	/**
	 * Datasource for the subfilter navbar
	 */
	subFilterDataSource: FilterBarDataSource<FilterBarResultParams> = null;

	@ViewChild('dashboard', {static: true}) dashboard: DashboardComponent;

	dataDashboard: DashboardGridData;

	get safeHtml(): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(gv(() => this.dataEntryGrid.data.config.layout.pageDescription, ''));
	}


	get renderAsForm(): boolean {
		return gv(() => this.dataEntryGrid.data.config.layout.renderAsForm, false);
	}

	get iconType() {
		const messageType = 'info';
		switch (messageType) {
			// case 'warning':
			// 	return 'mdi mdi-18px mdi-alert-circle-outline hover-help';
			// case 'danger':
			// 	return 'mdi mdi-18px mdi-close-circle-outline hover-help';
			case 'info':
				return 'mdi mdi-18px mdi-information-outline hover-help';
			default:
				return '';
		}
	}

	constructor(public readonly config: DataEntryConfigService,
				public readonly dataEntryAgent: DataEntryAgent,
				protected readonly i8n: TranslateService,
				protected readonly router: Router,
				private readonly activeRoute: ActivatedRoute,
				private dataEntryStateQuery: DataEntryStateQuery,
				private dataEntryStateService: DataEntryStateService,
				protected readonly filterCompareBarQuery: FilterCompareBarQuery,
				private filterCompareBarService: FilterCompareBarService,
				private auditTrailStateQuery: AuditTrailStateQuery,
				private auditTrailStateService: AuditTrailStateService,
				private bottomBarService: BottomBarService,
				private csDataGridPopoversService: CsDataGridPopoversService,
				protected readonly appNavigationService: AppNavigationService,
				private injector: Injector,
				private changeRef: ChangeDetectorRef,
				@Optional() protected readonly dashboardConfigService: DashboardConfigService,
				protected readonly appService: AppService,
				protected readonly toastService: CsToastManagerService,
				@Inject(TabService) readonly tabService: TabService,
				private sanitizer: DomSanitizer,
				readonly appMessageHub: AppMessageHubService) {
		super(dashboardConfigService, filterCompareBarQuery, appNavigationService,
			  appService, changeRef, i8n, toastService, router, tabService, appMessageHub);
	}

	ngOnInit(): void {
		// Setup show chartpanel here because of not rendering the chartpanel on change when added to the ChartPanelAgent
		this.showChart$                = this.dataEntryStateQuery.select(store => store.showChart);
		this.isStatisticsPanelLoading$ = this.dataEntryStateQuery.select(store => store.isStatisticsPanelLoading);
		this.isChartPanelLoading$      = this.dataEntryStateQuery.select(store => store.isChartPanelLoading);
		this.isLoadingDataGrids$       = this.dataEntryStateQuery.select(store => store.isDataGridsLoading);
		this.isLoadingNewContent$      = this.dataEntryStateQuery.select(store => store.isLoadingNewContent);
		this.enableScrollDetection$    = this.dataEntryStateQuery.select(store => store.enableScrollDetection);
		this.isChangesPanelVisible$    = this.auditTrailStateQuery.select(store => store.isChangesPanelVisible);
		this.resizeObservable$         = fromEvent(window, 'resize');

		this.resizeObservable$.pipe(
				untilDestroyed(this),
				debounceTime(300))
			.subscribe((evt) => {

				if (!this.dataEntryGrid == null)
					return;

				this.dataEntryGrid.updateCellWidth();
				this.detectChanges();
			});


	}

	onShowDetailsButtonClick($event: SelectionTargetResult) {
		this.onDashboardEntryClick($event);
	}

	onDashboardEntryClick($event: SelectionTargetResult) {
		onDashboardEntryClick($event, this.injector, this.dashboardConfigService, this.data.name)
			.then(value => handlePostAction(value, this.injector));
	}

	ngAfterViewInit(): void {
		this.getAllMembers()
			.subscribe(value => {
				this.filterCompareBarService.filterbarSelectionChanged.pipe(
						untilDestroyed(this)
					)
					.subscribe(value => {
						this.bottomBarService.registerChange(0);
						this.bottomBarService.toggleBottomBar(false);
						this.dataEntryStateService.update({
															  isStatisticsPanelLoading: true,
															  isChartPanelLoading:      true,
															  isDataGridsLoading:       true
														  });
					});

				this.filterCompareBarQuery.select(store => store.comparebarResultParams)
					.pipe(
						untilDestroyed(this)
					)
					.subscribe(value => {
						if (value) {
							this.applyCompareDataToDataGrid(value as DataEntryResultParams);
						}
					});
				this.filterCompareBarService.toggleCompareBarComplete.subscribe(value => {
					if (!value && !isNullOrUndefined(this.dataEntryGrid)) {
						this.dataEntryGrid.cleanCompare();
					}
				});

				this.bottomBarService.onButtonClicked.pipe(untilDestroyed(this))
					.subscribe(button => {
						this.dataEntryAgent.bottomBarButtonClicked(button, this);
					});

				this.filterCompareBarQuery.select(store => store.mainbarResultParams)
					.pipe(
						untilDestroyed(this),
						filter(value => !isNullOrUndefined(value))
					)
					.subscribe((value: DataEntryResultParams) => {
						this.renderDataGrid(value, Object.assign({}, value, {selection: undefined}));
					});

				// update audit trail details when they are available
				this.auditTrailStateQuery.select(state => state.changesData)
					.pipe(
						untilDestroyed(this)
					)
					.subscribe(value => {
						// component is only shown when need, check if exists
						if (isNullOrUndefined(this.auditChanges))
							return;

						this.auditChanges.metaData = new TableDataDescribed<[]>(value.meta);
						this.auditChanges.data     = new TableDataDescribed<[]>(value.data);
					});
			});

	}

	ngOnDestroy(): void {
		if (this.isRegisteredForNavigationChanges)
			this.appNavigationService.unregisterForStoppingNavigationChanges(this.ticket);
	}


	renderDataGrid(value: DataEntryResultParams, params = {}, subFilterRequest: boolean = false) {

		if (isNullOrUndefined(value.dataEntryGrid) || value.dataEntryGrid === '')
			return;

		if (!isNullOrUndefined(this.currentRequest) && !this.currentRequest.closed)
			this.currentRequest.unsubscribe();

		params = this.patchParamsWithDataGridSubFilters(this.subFilterDataSource, params);

		this.currentRequest = this.config
								  .getInitBundle(value.dataEntryGrid, value.selection, params)
								  .pipe(untilDestroyed(this))
								  .subscribe(result => {
									  const initBundle = result.value;

									  if (initBundle.structureData.dimensionTrees.filters
										  && initBundle.structureData.dimensionTrees.filters.length > 0) {
										  const value1 = dimensionTreeToFilterNavbar(initBundle.structureData.dimensionTrees.filters, false);

										  this.subFilterDataSource = value1;
										  this.dataEntryStateService.setDataGridSubFilter(this.subFilterDataSource.resultParams);
										  this.patchBaseKeysWithSubfilterKeys(this.subFilterDataSource.resultParams, initBundle);

										  this.router.navigate([], {
											  queryParamsHandling: 'merge',
											  queryParams:         flattenObject({gridFilterBar: value1.resultParams})
										  });

									  } else {
										  this.subFilterDataSource = null;
										  this.router.navigate([], {
											  queryParamsHandling: 'merge',
											  queryParams:         Object.keys(this.activeRoute.snapshot.queryParams)
																		 .reduce((previousValue, currentValue) => {
																			 const gridFilter = previousValue;
																			 // if the key starts with gridfilter remove it
																			 if (currentValue.startsWith('gridFilterBar')) {
																				 gridFilter[currentValue] = undefined;
																			 }

																			 return gridFilter;
																		 }, {})
										  });
									  }

									  if (!subFilterRequest) {

										  // this.dashboardConfigService.getDashboardData(value.selection, params)
										  // 		.subscribe(value1 => this.dataDashboard = value1.value);
										  this.updateStatsAndChart(initBundle, value);
									  }

									  if (!isNullOrUndefined(value.dataEntryGrids)) {
										  LoggerUtil.error('This page has multiple dataentygrids, to support this you need to add a custom module');
									  }

									  this.dataEntryGrid = new DataGridWorker(value.dataEntryGrid, this.grid, this.injector);

									  if (!this.isRegisteredForNavigationChanges) {
										  this.appNavigationService.registerForStoppingNavigationChanges(this.ticket, () => {
											  if (this.dataEntryGrid == null)
												  return true;

											  const hasChangedCells = this.dataEntryGrid.getChangedCells();
											  return hasChangedCells === 0 || confirm(this.i8n.instant('NAVIGATE'));
										  });
										  this.isRegisteredForNavigationChanges = true;
									  }

									  this.dataEntryStateService.update({
																			isDataGridsLoading: true
																		});

									  this.dataEntryGrid.updateData(initBundle)
										  .then(value1 => {
											  this.dataEntryStateService.update({
																					isDataGridsLoading: false
																				});
											  this.grid.updateCells(DataGridCellType.All);
											  this.changeRef.detectChanges();
										  });

								  });
	}

	refreshAllData() {
		const mainbarSelection = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
		this.renderDataGrid(mainbarSelection, Object.assign({}, mainbarSelection, {selection: undefined}));
	}

	updateStats(mainbarSelection: FilterBarResultParamsSelection = null, params: DataEntryResultParams = null) {

		if (mainbarSelection === null)
			mainbarSelection = this.filterCompareBarQuery.getValue().mainbarResultParams.selection;

		if (params === null)
			params = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;


		if (ChartPanelAgent.canShowChart(this.dataEntryGrid.data)) {

			if (isNullOrUndefined(this.chartPanelAgent)) {
				LoggerUtil.error('No chart agent defined, but should have one according to the main init bundle');
				return;
			}

			this.chartPanelAgent.getStatistics(mainbarSelection, params.dataEntryGrid, params.selection)
				.subscribe(value1 => {
					this.dataEntryStateService.update({
														  isStatisticsPanelLoading: false
													  });

					this.changeRef.detectChanges();
				});
		}
	}

	updateStatsAndChart(initBundle: IInitData = null, params: DataEntryResultParams = null) {

		if (initBundle === null)
			initBundle = this.dataEntryGrid.data;

		if (params === null)
			params = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;

		if (ChartPanelAgent.canShowChart(initBundle)) {

			if (isNullOrUndefined(this.chartPanelAgent))
				this.chartPanelAgent = new ChartPanelAgent(this.chartPanel, this.injector);

			this.dataEntryStateService.showChartPanel();

			this.chartPanelAgent.initChart(initBundle, initBundle.config.structure.chartSeries.native.selection)
				.subscribe(value1 => {
					this.dataEntryStateService.update({
														  isChartPanelLoading: false
													  });

					this.changeRef.detectChanges();
				});

			this.chartPanelAgent.getStatistics(params.selection, params.dataEntryGrid)
				.subscribe(value1 => {
					this.dataEntryStateService.update({
														  isStatisticsPanelLoading: false
													  });

					this.changeRef.detectChanges();
				});

		} else {
			this.dataEntryStateService.disableChartPanel();
		}
	}

	detectChanges() {
		SafeMethods.detectChanges(this.changeRef);
	}

	/**
	 * Closes / hides the panel
	 */
	hideChangesPanel() {
		this.auditTrailStateService.closeDetailsPanel();
	}

	statisticsChanged($event: IDataSource) {
		if ($event.type !== 'AuditTrailAudits') {
			this.auditTrailStateService.closeDetailsPanel();
		}
	}

	/**
	 * The handler for subfilter selection.
	 * @param $event The selected item
	 */
	subFilterBarSelectionChanged($event: FilterSelectionChangedEventArgs) {
		const mainbarSelection                = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
		// patch the sub filters
		this.subFilterDataSource.resultParams = {...this.subFilterDataSource.resultParams, ...$event.newApiParams};

		this.dataEntryStateService.setDataGridSubFilter(this.subFilterDataSource.resultParams);

		this.dataEntryStateService.update({
											  isDataGridsLoading: true
										  });

		this.renderDataGrid(mainbarSelection, Object.assign({}, mainbarSelection,
															{selection: undefined}), true);
	}

	patchBaseKeysWithSubfilterKeys(resultParams: FilterBarResultParams, initBundle: IInitData) {
		const filteredObject = {};
		for (const key of Object.keys(resultParams)) {
			const value = resultParams[key];

			// Ignore all values that are 'id' === 0 this is used as filter for 'ALL' items
			// tslint:disable-next-line:triple-equals
			if (value != 0) {
				filteredObject[key] = value;
			}
		}

		Object.assign(initBundle.structureData.baseKeys, filteredObject);
	}

	prepareLevelMembers(data: {
		[memberKey: string]: Array<any>
	}) {
		const levelMembers = Object.keys(data);
		const memberResult = {};

		for (const levelMember of levelMembers) {
			const levelMemberData   = data[levelMember] as Array<any>;
			const levelMemberResult = {};
			const headers           = levelMemberData.splice(0, 1)[0];
			const idHeaderIndex     = headers.findIndex(value => value === 'id');

			for (const dataRow of levelMemberData) {
				levelMemberResult[dataRow[idHeaderIndex]] = FastZipObject.createZipObject(headers, dataRow);
			}

			memberResult[levelMember] = levelMemberResult;

		}
		return memberResult;
	}

	/**
	 * Angular instance of the chartpanel on this page
	 */
	@ViewChild('chartPanel')
	private chartPanel: CsChartPanelComponent;

	/**
	 * Angular instance of the audit trail changes component
	 */
	@ViewChild('auditChanges')
	private auditChanges: AuditTrailChangesComponent;
	/**
	 * unique id for the page
	 */
	private ticket: string                   = generateQuickGuid();
	/**
	 * Handle for the window resize event
	 */
	private resizeObservable$: Observable<Event>;
	private isRegisteredForNavigationChanges = false;


	private getAllMembers() {
		const obs = new Observable<boolean>(observer => {
			if (!isNullOrUndefined(window['members'])) {
				observer.next(true);
				observer.complete();
			} else {
				window['members'] = {};
				let common        = {};
				this.config.getCommonMembers()
					.subscribe(
						result => {
							common            = this.prepareLevelMembers(result.value);
							window['members'] = common;
							this.config.getExtraMembers()
								.subscribe(
									resultExtra => {
										window['members'] = Object.assign(common, this.prepareLevelMembers(resultExtra.value));
										observer.next(true);
										observer.complete();
									},
									err => {
										observer.error(err);
									}
								);
						},
						err => {
							observer.error(err);
						}
					);
			}
		});
		return obs;
	}

	private patchParamsWithDataGridSubFilters(subFilterGrid: FilterBarDataSource<any>, params: {}) {
		if (subFilterGrid == null) {
			let subFilterGridSelection;
			// Check in the query params if the subfilter has selected values
			const restoredParams = restoreFlattenObject(this.activeRoute.snapshot.queryParams) as {
				gridFilterBar: {
					[key: string]: any
				}
			};
			if (restoredParams.gridFilterBar) {
				subFilterGridSelection = restoredParams.gridFilterBar;
				this.dataEntryStateService.setDataGridSubFilter(subFilterGridSelection);

				return Object.assign({}, params, {filters: DataGridUtils.convertToSelectionKey(subFilterGridSelection)});
			}
			return params;
		} else {

			const subFilterGridSelection = subFilterGrid.resultParams;

			this.dataEntryStateService.setDataGridSubFilter({});
			return Object.assign({}, params, {filters: DataGridUtils.convertToSelectionKey(subFilterGridSelection)});
		}
	}

	private applyCompareDataToDataGrid(value: DataEntryResultParams) {

		if (isNullOrUndefined(value.dataEntryGrid) || value.dataEntryGrid === '')
			return;

		this.dataEntryStateService.update({
											  isStatisticsPanelLoading: true
										  });

		this.config.getInitBundle(value.dataEntryGrid, value.selection, Object.assign({}, value, {selection: undefined}))
			.subscribe(result => {

				const initBundle = result.value;

				const mainbarSelection = this.filterCompareBarQuery.getValue().mainbarResultParams.selection;
				this.updateStats(mainbarSelection, value);
				if (!isNullOrUndefined(value.dataEntryGrids)) {
					LoggerUtil.error('This page has multiple dataentygrids, to support this you need to add a custom module');
				}

				this.dataEntryGrid.updateCompareData(initBundle)
					.then(value1 => {
						this.dataEntryStateService.update({
															  isDataGridsLoading:  false,
															  isChartPanelLoading: false
														  });
					});
			});

	}
}



