import { CommonModule }                                               from '@angular/common';
import { HttpClient }                                                 from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type }                        from '@angular/core';
import { ComponentTranslationLoaderService, CsCultureProvider }       from '@cs/common';
import { CsFormGeneratorModule }                                      from '@cs/components/form-generator';
import { CsToastManagerModule }                                       from '@cs/components/toast-manager';
import { LoggerUtil }                                                 from '@cs/core';
import { CsApplicationSettings, CsTranslationLoader, IConfigService } from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService }         from '@ngx-translate/core';
import { UserProfileConfigService }                                   from './user-profile-config.service';
import { UserProfileRoutingModule }                                   from './user-profile-routing.module';
import { UserProfileComponent }                                       from './user-profile.component';


export function userProfileTranslationLoader(client: HttpClient,
											 pmAppSettings: CsApplicationSettings,
											 service: IConfigService,
											 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [UserProfileComponent],
			  imports     : [
				  CommonModule,
				  CsToastManagerModule,
				  CsFormGeneratorModule,
				  TranslateModule.forChild({
											   loader : {
												   provide   : TranslateLoader,
												   useFactory: userProfileTranslationLoader,
												   deps      : [HttpClient, CsApplicationSettings, UserProfileConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  UserProfileRoutingModule
			  ]
		  })
export class PmUserProfileModule {
	static forRoot(moduleConfig: Type<UserProfileConfigService>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<PmUserProfileModule> {
		return {
			ngModule : PmUserProfileModule,
			providers: [
				{
					provide : UserProfileConfigService,
					useClass: moduleConfig
				},
				{
					provide : CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}


	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
