import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DashboardGridData }                                             from '@cs/components/dashboard';
import { convertToSelectionObjects }                                     from '@cs/core/utils';
import { DashboardConfigService }                                        from '@cs/performance-manager/dashboard';
import { UntilDestroy, untilDestroyed }                                  from '@ngneat/until-destroy';
import { filter, takeWhile }                                             from 'rxjs/operators';
import { ReportingQuery, ReportingStateService }                         from '../state';


@UntilDestroy()
@Component({
			   selector:        'pmc-reporting-dashboard',
			   templateUrl:     './reporting-dashboard.component.html',
			   styleUrls:       ['./reporting-dashboard.component.css'],
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class ReportingDashboardComponent implements OnInit {

	data: DashboardGridData;

	constructor(private reportingStateService: ReportingStateService,
				private dashboardConfig: DashboardConfigService,
				private reportingStateQuery: ReportingQuery,
				private changeDetection: ChangeDetectorRef) {


	}

	ngOnInit(): void {
		this.reportingStateQuery
			.select(store => store.selection)
			.pipe(untilDestroyed(this),
				  takeWhile(() => this.reportingStateQuery.getValue().actAsDashboard),
				  filter(value => value !== null))
			.subscribe(value => {

				this.getDashboard(value.selection);
			});

	}

	private getDashboard(selection: {
		[key: string]: any
	}): void {

		this.dashboardConfig.getDashboardData({
												  dashboard: this.reportingStateQuery.getValue()
																 .activeReport
																 .meta.name,
												  selection: convertToSelectionObjects(selection)
											  })
			.subscribe(result => {
				this.data = result.value;
				this.changeDetection.detectChanges();
			});
	}
}
