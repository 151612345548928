import { Lookup }                     from '../data-described/lookup.model';
import { FormatRegisteredItem }       from '../data-described/formatting.models';
import { TableConditionalAnnotation } from './table-conditional-annotation.model';
import { getPropertyOf }              from '@cs/core/utils';

export class TableHeader {
	/**
	 * The lookup that should be used to resolve the data
	 */
	lookup: Lookup;
	/**
	 * Use this template for the header
	 */
	template: string;

	/**
	 * Use this template for the cell
	 */
	cellTemplate: string;
	/**
	 * the Format string for the cell
	 */
	cellFormat: FormatRegisteredItem;
	/**
	 * Set the colspan of the header
	 */
	colSpan?: number;
	/**
	 * A list of classes for styling the header cell
	 */
	classList?: Array<string> = [];
	/**
	 * Indicates if the column is readonly, could be overridden by the cell
	 */
	readOnly: boolean;
	/**
	 * value use to set the tooltip
	 */
	description: string;

	conditionalFormatting: TableConditionalAnnotation[];

	/**
	 * Allow to filter the column based on the value
	 */
	allowAsFilter: boolean;
	/**
	 * Truncate table header
	 */
	truncate: boolean;
	/**
	 * Indicates is the column is sortable when server side paging is active
	 */
	isSortable: boolean;
	/**
	 * Flag indicating that the column is filterable
	 */
	hasFilter: boolean;
	_label: string;
	/**
	 * Allow the server to set the width directly
	 */
	width: string;
	/**
	 * Use this template for the cell when no lookup value is found
	 */
	noLookupFallbackTemplate: string;
	/**
	 * Lookup name, use this for checking if the header has a lookup. Because this is always
	 * there in contrary with the lookup, that could be empty.
	 */
	lookupName: string;

	/**
	 * Sometimes there's a need for default values when no value is provided. like with new rows
	 */
	defaultValue: any;

	/**
	 * Identification for the header
	 */
	id: string;

	/**
	 * value that is used to display the value in the UI
	 */
	displayValue: string;

	/**
	 * type of the data
	 */
	dataType?: string;

	get value(): any {
		return this._value;
	}

	set value(value: any) {
		this._value       = value;
		this.displayValue = value;
	}

	constructor(id: string, value: any, init: Partial<TableHeader> = {}) {
		this.id                       = id;
		this.value                    = value;
		this.cellFormat               = getPropertyOf(init, 'cellFormat', null);
		this.cellTemplate             = getPropertyOf(init, 'cellTemplate', null);
		this.readOnly                 = getPropertyOf(init, 'readOnly', false);
		this.classList                = getPropertyOf(init, 'classList', []);
		this.description              = getPropertyOf(init, 'description', '');
		this.allowAsFilter            = getPropertyOf(init, 'allowAsFilter', true);
		this.conditionalFormatting    = getPropertyOf(init, 'conditionalFormatting', []);
		this.dataType                 = getPropertyOf(init, 'dataType', null);
		this.truncate                 = getPropertyOf(init, 'truncate', true);
		this.isSortable               = getPropertyOf(init, 'isSortable', false);
		this.hasFilter                = getPropertyOf(init, 'hasFilter', false);
		this.width                    = getPropertyOf(init, 'width', null);
		this.defaultValue             = getPropertyOf(init, 'defaultValue', null);
		this.noLookupFallbackTemplate = getPropertyOf(init, 'noLookupFallbackTemplate', null);
	}

	/**
	 * the value we manipulate
	 */
	private _value: any;
}
