import { CommonModule }                                                          from '@angular/common';
import { HttpClient }                                                            from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type }                                   from '@angular/core';
import { ComponentTranslationLoaderService, CsCultureModule, CsCultureProvider } from '@cs/common';
import { CsPlaceholderModule }                                                   from '@cs/components/placeholder';
import { LoggerUtil }                                                            from '@cs/core';
import { CsApplicationSettings, CsTranslationLoader, IConfigService }            from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService }                    from '@ngx-translate/core';
import { NewsConfigService }                                                     from './news-config.service';
import { NewsListModule }                                                        from './news-list/news-list.module';
import { NewsRoutingModule }                                                     from './news-routing.module';
import { NewsComponent }                                                         from './news.component';


export function newsTranslationLoader(client: HttpClient,
									  pmAppSettings: CsApplicationSettings,
									  service: IConfigService,
									  componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [NewsComponent],
			  imports     : [
				  CommonModule,
				  CsCultureModule,
				  TranslateModule.forChild({
											   loader : {
												   provide   : TranslateLoader,
												   useFactory: newsTranslationLoader,
												   deps      : [HttpClient, CsApplicationSettings, NewsConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  NewsListModule,
				  CsPlaceholderModule,
				  NewsRoutingModule
			  ]
		  })
export class PmNewsModule {
	static forRoot(moduleConfig: Type<NewsConfigService>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<PmNewsModule> {
		return {
			ngModule : PmNewsModule,
			providers: [
				{
					provide : NewsConfigService,
					useClass: moduleConfig
				},
				{
					provide : CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
